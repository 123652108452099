<template>
  <div>
    <div class="py-5 text-center">
      <img src="./images/icon-check-circle.svg" />
      <div class="mt-3 font-weight-bold">預購清單已送出</div>
      <p class="one-line-note">*請留意手機來電，專櫃人員將與您聯繫</p>
    </div>

    <section class="custom-note-section">
      <h2 class="custom-note-section__title">貼心提醒</h2>
      <div class="custom-note-section__content" v-html="customNote"></div>
    </section>

    <div class="sticky-bottom footer-action-bar">
      <router-link
        class="btn w-100 btn-secondary btn-lg"
        :to="{ name: 'LiffPreorderProducts' }"
      >
        我的預購清單
      </router-link>
      <router-link
        class="btn w-100 btn-primary btn-lg"
        :to="{ name: 'LiffPreorderProducts' }"
      >
        回官方帳號
      </router-link>
    </div>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";

export default {
  components: {
  },
  mixins: [themeColor],
  data() {
    return {
      customNote: '<h3 style="font-weight: bold; font-size: 14px">【點單須知】</h3><ul style="font-size: 12px"><li>此清單僅作預選用途</li><li>此預選單紀錄僅保留 7 日</li></ul>'
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";

.one-line-note {
  font-size: 0.875rem;
  color: #888;
}

.custom-note-section {
  margin: 16px 16px 32px;
  padding: 16px;
  background: white;

  &__title {
    margin-bottom: 16px;
    font-size: 0.875rem;
    text-align: center;
  }
}

.footer-action-bar {
  padding: 8px;
  background-color: white;
  box-shadow: 0px -2px 4px 0px #0000000d;

  .btn {
    border-width: 2px;
    padding: 0.8rem;
    font-weight: 500;
    border-radius: 0.5rem;

    &.btn-primary {
      background-color: var(--s-primary);
      border-color: var(--s-primary);
    }
  }

  .btn + .btn {
    margin-top: 8px;
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
